/* Style de base du calendrier */
.react-datepicker {
    font-family: inherit;
    border: none !important;
    border-radius: 0.5rem;
    background-color: rgba(17, 25, 40, 0.75) !important;
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    padding: 1rem;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

/* En-tête du calendrier */
.react-datepicker__header {
    background-color: transparent !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
    padding: 1rem 0;
}

.react-datepicker__current-month {
    color: white !important;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.react-datepicker__day-names {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    padding: 0 0.5rem;
}

.react-datepicker__day-name {
    color: #9CA3AF !important;
    width: 2.5rem;
    margin: 0.1rem;
    font-size: 0.875rem;
}

/* Jours du calendrier */
.react-datepicker__month {
    margin: 0.4rem 0;
    background-color: transparent !important;
}

.react-datepicker__day {
    padding: 0.1rem;
    width: 2.5rem !important;
    height: 3.2rem !important;
    color: white !important;
    border-radius: 0.5rem !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.react-datepicker__day:hover {
    background-color: rgba(59, 130, 246, 0.2) !important;
}

/* Jours sélectionnés */
.react-datepicker__day--selected,
.react-datepicker__day--in-range {
    background-color: #2563EB !important;
    color: white !important;
}

.react-datepicker__day--in-selecting-range {
    background-color: rgba(37, 99, 235, 0.5) !important;
}

/* Jours désactivés */
.react-datepicker__day--disabled {
    color: #4B5563 !important;
    text-decoration: line-through;
    opacity: 0.5;
}

/* Contenu des jours */
.day-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0.1rem;
}

.day-number {
    font-size: 0.875rem;
    font-weight: 500;
}

.day-price {
    font-size: 0.625rem;
    color: #E5E7EB;
    padding: 0.1rem 0.3rem;
    border-radius: 0.25rem;
    white-space: nowrap;
}

/* Style des prix pour les jours sélectionnés */
.react-datepicker__day--selected .day-price,
.react-datepicker__day--in-range .day-price {
    color: white;
}

/* Navigation du calendrier */
.react-datepicker__navigation {
    top: 1rem;
}

.react-datepicker__navigation--previous {
    left: 1rem;
}

.react-datepicker__navigation--next {
    right: 1rem;
}

.react-datepicker__navigation-icon::before {
    border-color: white !important;
    border-width: 2px 2px 0 0 !important;
    height: 8px;
    width: 8px;
}

/* Jours en dehors du mois */
.react-datepicker__day--outside-month {
    color: #6B7280 !important;
    opacity: 0.5;
}

/* Style pour les weekends */
.react-datepicker__day--weekend:not(.react-datepicker__day--selected):not(.react-datepicker__day--in-range) {
    color: #BFDBFE !important;
}

/* Animation de transition */
.react-datepicker__day {
    transition: all 0.2s ease-in-out;
}

/* Support mobile */
@media (max-width: 640px) {
    .react-datepicker {
        padding: 0.5rem;
        max-width: 340px;
    }

    .react-datepicker__day {
        width: 2.2rem !important;
        height: 2.8rem !important;
        margin: 0.1rem;
    }

    .react-datepicker__day-name {
        width: 2.2rem;
    }

    .day-number {
        font-size: 0.8rem;
    }

    .day-price {
        font-size: 0.5rem;
        padding: 0.1rem 0.2rem;
    }
} 